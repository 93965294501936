#sha{
   width: 98%; 
   height: 550px;
   margin-top: -50px;
   margin-left: 10px;
   box-shadow: 0px 4px 9px gray;
}
#cli{
    margin-top: 130px;
    color: rgb(66, 65, 65);
    padding-top: 20px;
    font-size: 16px;
    margin-right: 500px;
    margin-left: 50px;
    line-height: px;
    word-spacing: 6px;
}
#ose{
  width: 45%;
  margin-left: 590px;  
  height: 350px;
  margin-bottom: -650px;
  margin-bottom: 300px;
  margin-top: -460px;
}
#ght{
    width:98% ;
    height: 350px;
    margin-top: -100px;
    margin-left: 10px;
    box-shadow: 0px 4px 9px gray;
}
#el{
    margin-left: 50px;
    color:rgb(66, 65, 65) ;
    margin-top: 140px;
    padding-top: 70px;
    margin-right: 20px;
    font-size: 17px;
    line-height: 30px;
    margin-right: 550px;
}
#iw{
    width: 30%;
    margin-left:630px ;
    height: 400px;
    margin-top: -300px;
    margin-bottom: 390px;
}
/* Styles for screens up to 425px width */
@media screen and (max-width: 425px) {
    #sha {
        width: 95%; /* Adjusted width for better fit */
        height: 950px; /* Reduced height */
        margin-top: -60px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }
    #sou{
        margin-top: 500px;
    }

    #cli {
        margin-top: 100px; /* Adjusted margin top */
        color: rgb(66, 65, 65);
        padding-top: 20px;
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        margin-right: 10px; /* Adjusted right margin */
        line-height: 24px; /* Adjusted line height */
        word-spacing: 4px; /* Reduced word spacing */
    }

    #ose {
        width: 100%; /* Full width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -40px; /* Adjusted margin top */
        margin-bottom: 50px; /* Adjusted margin bottom */
    }

    #ght {
        width: 95%; /* Adjusted width for better fit */
        height: 350px; /* Reduced height */
        margin-top: -80px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #el {
        margin-left: 10px; /* Adjusted left margin */
        color: rgb(66, 65, 65);
        margin-top: 120px; /* Adjusted margin top */
        padding-top: 50px; /* Adjusted padding top */
        margin-right: 10px; /* Adjusted right margin */
        font-size: 14px; /* Reduced font size */
        line-height: 24px; /* Adjusted line height */
        word-spacing: 4px; /* Reduced word spacing */
    }
    #in{
        height: 1090px;
    }
    #pmn{
        margin-left: 20px;
    }

    #iw {
        width: 30%; /* Full width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -60px; /* Adjusted margin top */
        margin-bottom: 50px; /* Adjusted margin bottom */
    }
}

/* Styles for screens from 426px to 1024px width */
@media screen and (max-width: 320px) {
    #sha {
        width: 95%; /* Adjusted width for better fit */
        height: 1050px; /* Reduced height */
        margin-top: -60px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }
    #sou{
        margin-top: 500px;
    }

    #cli {
        margin-top: 100px; /* Adjusted margin top */
        color: rgb(66, 65, 65);
        padding-top: 20px;
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        margin-right: 10px; /* Adjusted right margin */
        line-height: 24px; /* Adjusted line height */
        word-spacing: 4px; /* Reduced word spacing */
    }

    #ose {
        width: 100%; /* Full width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -40px; /* Adjusted margin top */
        margin-bottom: 50px; /* Adjusted margin bottom */
    }

    #ght {
        width: 95%; /* Adjusted width for better fit */
        height: 350px; /* Reduced height */
        margin-top: -80px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #el {
        margin-left: 10px; /* Adjusted left margin */
        color: rgb(66, 65, 65);
        margin-top: 120px; /* Adjusted margin top */
        padding-top: 50px; /* Adjusted padding top */
        margin-right: 10px; /* Adjusted right margin */
        font-size: 14px; /* Reduced font size */
        line-height: 24px; /* Adjusted line height */
        word-spacing: 4px; /* Reduced word spacing */
    }
    #in{
        height: 1290px;
    }
    #pmn{
        margin-left: 20px;
    }

    #iw {
        width: 30%; /* Full width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -60px; /* Adjusted margin top */
        margin-bottom: 50px; /* Adjusted margin bottom */
    }
}

/* Styles for screens from 426px to 1024px width */
@media screen and (max-width: 768px) {
    #sha {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        margin-top: -30px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #sou {
        margin-top: 20px; /* Adjusted margin top */
    }

    #cli {
        margin-top: 80px; /* Adjusted margin top */
        color: rgb(66, 65, 65);
        padding-top: 10px; /* Reduced padding top */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        margin-right: 10px; /* Adjusted right margin */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #ose {
        width: 100%; /* Full width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -10px; /* Adjusted margin top */
        margin-bottom: 30px; /* Adjusted margin bottom */
    }

    #ght {
        width: 95%; /* Adjusted width for better fit */
        height: 300px; /* Reduced height */
        margin-top: -60px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #el {
        margin-left: 10px; /* Adjusted left margin */
        color: rgb(66, 65, 65);
        margin-top: 100px; /* Adjusted margin top */
        padding-top: 30px; /* Adjusted padding top */
        margin-right: 10px; /* Adjusted right margin */
        font-size: 14px; /* Reduced font size */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #in {
        height: auto; /* Allow height to adjust */
    }

    #pmn {
        margin-left: 10px; /* Adjusted left margin */
    }

    #iw {
        width: 20%; /* Adjusted width */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -40px; /* Adjusted margin top */
        margin-bottom: 30px; /* Adjusted margin bottom */
    }
}
@media screen and (min-width: 1024px){
    #sha {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        margin-top: -30px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #sou {
        margin-top: 20px; /* Adjusted margin top */
    }

    #cli {
        margin-top: 80px; /* Adjusted margin top */
        color: rgb(66, 65, 65);
        padding-top: 10px; /* Reduced padding top */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        margin-right: 530px; /* Adjusted right margin */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #ose {
        width: 45%; /* Full width */
        
        margin-left: 560px; /* Center horizontally */
        margin-right: auto;
        height: 400PX; /* Allow height to adjust */
        margin-top: -430px; /* Adjusted margin top */
     margin-bottom: 30PX;
        padding-top: -300px; /* Adjusted margin bottom */
    }

    #ght {
        width: 95%; /* Adjusted width for better fit */
        height: 300px; /* Reduced height */
        margin-top: -60px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #el {
        margin-left: 10px; /* Adjusted left margin */
        color: rgb(66, 65, 65);
      
        margin-top: 100px; /* Adjusted margin top */
        padding-top: 90px; /* Adjusted padding top */
        margin-right: 500px; /* Adjusted right margin */
        font-size: 14px; /* Reduced font size */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #in {
        height: auto; /* Allow height to adjust */
    }

    #pmn {
        margin-left: 50px; /* Adjusted left margin */
    }

    #iw {
        width: 20%; /* Adjusted width */
        margin-left: 590px; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -180px; /* Adjusted margin top */
        margin-bottom: 30px; /* Adjusted margin bottom */
    }
}
@media screen and (min-width: 1440px){
    #sha {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        margin-top: -30px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #sou {
        margin-top: 20px; /* Adjusted margin top */
    }

    #cli {
        margin-top: 80px; /* Adjusted margin top */
        color: rgb(66, 65, 65);
        padding-top: 90px; /* Reduced padding top */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        margin-right: 630px; /* Adjusted right margin */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #ose {
        width: 45%; /* Full width */
        
        margin-left: 750px; /* Center horizontally */
        margin-right: auto;
        height: 400PX; /* Allow height to adjust */
        margin-top: -360px; /* Adjusted margin top */
     margin-bottom: 30PX;
        padding-top: -300px; /* Adjusted margin bottom */
    }

    #ght {
        width: 95%; /* Adjusted width for better fit */
        height: 340px; /* Reduced height */
        margin-top: -60px; /* Adjusted margin top */
        margin-left: auto; /* Center horizontally */
        margin-right: auto;
        box-shadow: 0px 3px 6px gray; /* Lighter shadow */
    }

    #el {
        margin-left: 10px; /* Adjusted left margin */
        color: rgb(66, 65, 65);
      
        margin-top: 100px; /* Adjusted margin top */
        padding-top: 90px; /* Adjusted padding top */
        margin-right: 540px; /* Adjusted right margin */
        font-size: 14px; /* Reduced font size */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
    }

    #in {
        height: auto; /* Allow height to adjust */
    }

    #pmn {
        margin-left: 50px; /* Adjusted left margin */
    }

    #iw {
        width: 20%; /* Adjusted width */
        margin-left: 890px; /* Center horizontally */
        margin-right: auto;
        height: auto; /* Allow height to adjust */
        margin-top: -180px; /* Adjusted margin top */
        margin-bottom: 30px; /* Adjusted margin bottom */
    }
}
