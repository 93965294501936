nav ul {margin: 0;padding: 0;list-style: none;background-color:#ff9e03;}
nav ul li {display: inline-block;position: relative; color: white;}
nav ul li a{display: block;padding: 5px 25px;text-decoration: none;color: #333;
list-style-type: NONE;}
nav ul li:hover > ul { display: block; color: white;} 
nav ul ul {display: none;position: absolute;top: 100%;left: 0;background-color: #fff;border: 1px solid #ccc;}
nav ul ul  li {display: block;}
nav ul ul li a {padding: 8px 14px;}

nav ul li:hover {background-color:#6ec522; color: white;}
  header{width:100%;height:auto;top:0;background-color: #4070f4;}
                .logo1{width:5%;float:left;}
                .name1{width:89%;float:left;text-align: center;}
                .logo2{width:5%;float:left;}
                .logimg{height:80px;width:80px;padding:5px 5px 5px 5px;border-radius: 35px;}
                .bvc{
                    margin-top: 130px;
                   
                }
                .log{
                    height: 134px;
                    margin-top: 1px;
                    margin-left: 10px;
                }
                .logs{
                    height: 134px;
                    margin-top: 1px;
                    margin-left: -90px;
                }
                @media (max-width: 1440px) {
                    .logimg {
                        height: 60px;
                        width: 60px;
                    }
                    nav ul li a{display: block;padding: 25px 25px;text-decoration: none;color: #333;}
                }
                
              
                @media (max-width: 1024px) {
                    .logimg {
                        height: 60px;
                        width: 60px;
                    }
                    nav ul li a{display: block;padding: 20px 25px;text-decoration: none;color: #333;}
                }
                @media (max-width: 1024px) {
                    .logimg {
                        height: 60px; /* Adjusted height for better fit */
                        width: 60px; /* Adjusted width for better fit */
                    }
                    nav ul li a {
                        display: block; /* Setting display to block for better layout */
                        padding: 15px 10px; /* Adjusted padding for better spacing */
                        text-decoration: none; /* Removed text decoration */
                        color: #333; /* Adjusted text color */
                    }
                }
                
                @media screen and (max-width: 320px) {
                    nav ul li {
                        display: block; /* Change display to block for full-width stacking */
                        text-align: center; /* Center-align list items */
                        margin-bottom: 10px; /* Add space between list items */
                    }
                
                    nav ul li:hover > ul {
                        display: none; /* Hide submenus on hover for small screens */
                    }
                
                    nav ul ul {
                        position: static; /* Remove absolute positioning */
                        width: 100%; /* Full width for submenus */
                        background-color: #fff; /* Background color for submenus */
                        border: none; /* Remove border */
                        box-shadow: none; /* Remove box shadow */
                    }
                
                    nav ul ul li {
                        display: block; /* Display submenu items as block */
                        text-align: center; /* Center-align submenu items */
                        margin-bottom: 5px; /* Add space between submenu items */
                    }
                
                    .logo1, .name1, .logo2 {
                        width: 100%; /* Full width for these elements */
                        float: none; /* Remove float */
                        text-align: center; /* Center-align text */
                    }
                
                    .logimg {
                        height: 60px; /* Adjusted height */
                        width: 60px; /* Adjusted width */
                        margin: 10px auto; /* Center-align and add margin */
                        display: block; /* Ensure it behaves as a block element */
                    }
                
                    .bvc {
                        margin-top: 20px; /* Adjusted margin top */
                    }
                
                     .logs {
                        height: 80px; /* Auto height */
                        margin: 30px auto; /* Center-align and add margin */
                    }
                    .log{
                        height: 90px;
                        margin-top: 150px;
                    }
                }
                
                @media screen and (max-width: 425px) {
                    nav ul li {
                        display: block; /* Change display to block for full-width stacking */
                        text-align: center; /* Center-align list items */
                        margin-bottom: 10px; /* Add space between list items */
                    }
                
                    nav ul li:hover > ul {
                        display: none; /* Hide submenus on hover for small screens */
                    }
                
                    nav ul ul {
                        position: static; /* Remove absolute positioning */
                        width: 100%; /* Full width for submenus */
                        background-color: #fff; /* Background color for submenus */
                        border: none; /* Remove border */
                        box-shadow: none; /* Remove box shadow */
                    }
                
                    nav ul ul li {
                        display: block; /* Display submenu items as block */
                        text-align: center; /* Center-align submenu items */
                        margin-bottom: 5px; /* Add space between submenu items */
                    }
                
                    .logo1, .name1, .logo2 {
                        width: 100%; /* Full width for these elements */
                        float: none; /* Remove float */
                        text-align: center; /* Center-align text */
                    }
                
                    .logimg {
                        height: 60px; /* Adjusted height */
                        width: 60px; /* Adjusted width */
                        margin: 10px auto; /* Center-align and add margin */
                        display: block; /* Ensure it behaves as a block element */
                    }
                
                    .bvc {
                        margin-top: 20px; /* Adjusted margin top */
                    }
                
                    .logs {
                        height: 80px; /* Adjusted height */
                        margin: 30px auto; /* Center-align and add margin */
                    }
                
                    .log {
                        height: 90px; /* Adjusted height */
                        margin-top: 150px; /* Adjusted margin top */
                    }
                }
                  
                @media screen and (max-width: 768px) {
                    .name1{
                        font-size: 11px;
                    }
                }
            