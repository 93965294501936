#wom{
    width: 550px;
    margin-left:590px ;
    height: 350px;
    margin-top: -235px;
    margin-bottom: 80px;
}
#lew{
    width: 98%;
    height: 400px;
    box-shadow: 0px 2px 9px gray;
    margin-left: 10px;
    margin-top: 30px;
}
#nts{
   margin-top: -20px; 
   color:rgb(66, 65, 65);
 padding-top:50px ;
 margin-right: 60px;
 font-size: 18px;
 margin-left: 30px;
 line-height: 24px;
 word-spacing:5px ;
 padding-right: 590px;
}
/* CSS for screens up to 320px width */
@media screen and (max-width: 320px) {
    #wom {
        width: 100%; /* Full width for smaller screens */
        margin-left: 0; /* Remove left margin */
        margin-top: 0px; /* Adjusted margin top */
        margin-bottom: 20px; /* Reduced margin bottom */
    }

    #lew {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        box-shadow: 0px 2px 6px gray; /* Lighter shadow for smaller screens */
        margin-left: 2.5%; /* Centering with slight left margin */
        margin-top: 20px; /* Adjusted margin top */
    }

    #nts {
        margin-top: -10px; /* Adjusted margin top */
        padding-top: 30px; /* Reduced padding top */
        margin-right: 20px; /* Reduced right margin */
        font-size: 16px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        line-height: 20px; /* Adjusted line height */
        word-spacing: 3px; /* Reduced word spacing */
        padding-right: 20px; /* Reduced right padding */
    }
}
/* CSS for screens up to 425px width */
@media screen and (max-width: 425px) {
    #wom {
        width: 100%; /* Full width for smaller screens */
        margin-left: 0; /* Remove left margin */
        margin-top: 0px; /* Adjusted margin top */
        margin-bottom: 20px; /* Reduced margin bottom */
    }

    #lew {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        box-shadow: 0px 2px 6px gray; /* Lighter shadow for smaller screens */
        margin: 20px auto; /* Centering with top and bottom margin */
        padding: 10px; /* Add padding for spacing */
    }

    #nts {
        margin-top: -10px; /* Adjusted margin top */
        padding-top: 20px; /* Reduced padding top */
        margin-right: 10px; /* Reduced right margin */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        line-height: 18px; /* Adjusted line height */
        word-spacing: 2px; /* Reduced word spacing */
        padding-right: 10px; /* Reduced right padding */
    }
}
@media screen and (max-width: 768px) {
    #wom {
        width: 100%; /* Full width for smaller screens */
        margin-left: 0; /* Remove left margin */
        margin-top: 0px; /* Adjusted margin top */
        margin-bottom: 20px; /* Reduced margin bottom */
    }

    #lew {
        width: 95%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        box-shadow: 0px 2px 6px gray; /* Lighter shadow for smaller screens */
        margin: 20px auto; /* Centering with top and bottom margin */
        padding: 10px; /* Add padding for spacing */
    }

    #nts {
        margin-top: -10px; /* Adjusted margin top */
        padding-top: 20px; /* Reduced padding top */
        margin-right: 10px; /* Reduced right margin */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        line-height: 18px; /* Adjusted line height */
        word-spacing: 2px; /* Reduced word spacing */
        padding-right: 10px; /* Reduced right padding */
    }
}

@media screen and (min-width: 1024px) {
    #wom {
        width: 47%; /* Full width for smaller screens */
        margin-left: 520px; /* Remove left margin */
        margin-top: -220px; /* Adjusted margin top */
        margin-bottom: 20px; /* Reduced margin bottom */
    }

    #lew {
        width: 97%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        box-shadow: 0px 2px 6px gray; /* Lighter shadow for smaller screens */
      
        padding: 10px; /* Add padding for spacing */
        margin-left: 10px;
    }

    #nts {
        margin-top: -10px; /* Adjusted margin top */
        padding-top: 120px; /* Reduced padding top */
        margin-right: 10px; /* Reduced right margin */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        line-height: 18px; /* Adjusted line height */
        word-spacing: 2px; /* Reduced word spacing */
        padding-right: 480px; /* Reduced right padding */
    }
}

@media screen and (min-width: 1440px) {
    #wom {
        width: 47%; /* Full width for smaller screens */
        margin-left: 740px; /* Remove left margin */
        margin-top: -170px; /* Adjusted margin top */
        margin-bottom: 20px; /* Reduced margin bottom */
    }

    #lew {
        width: 97%; /* Adjusted width for better fit */
        height: auto; /* Allow height to adjust */
        box-shadow: 0px 2px 6px gray; /* Lighter shadow for smaller screens */
      
        padding: 10px; /* Add padding for spacing */
        margin-left: 10px;
    }

    #nts {
        margin-top: -10px; /* Adjusted margin top */
        padding-top: 120px; /* Reduced padding top */
        margin-right: 10px; /* Reduced right margin */
        font-size: 14px; /* Reduced font size */
        margin-left: 10px; /* Adjusted left margin */
        line-height: 18px; /* Adjusted line height */
        word-spacing: 2px; /* Reduced word spacing */
        padding-right: 650px; /* Reduced right padding */
    }
}

