.slider {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.image {
  width: 900px;
  height: 500px;
  border-radius: 10px;
  margin-top: -90px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 2rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 2rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  margin-top: 10px;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
  z-index: -2;
}
.aim{
  margin-top: 10px;
  margin-left: 460px;
  padding-top: 4px;
  color:rgb(6, 6, 61);;
 
}
.aims{
  margin-top: 10px;
  margin-left: 100px;
  padding-top: 4px;
  color: white;
  padding-top: 5px;
  font-size: 22px;
 
}
.hip{
  background-color: #6ec522;
  margin-left: 20px;
  margin-right: 10px;
  height: 40px;
  width: 94%;
  
}
.i{
  margin-left: 40px;
  margin-top: 10px;
  font-size: 21px;
  color: #292727;
}
.ince{
  margin-left: 0px;
  margin-top: 30px;
  font-size: 17px;
  word-spacing: 5px;
  color: #292727;
  
}

.inc{
  margin-left: 40px;
  margin-top: -10px;
 color: #353434;
  font-size: 21px;
  
}


.fpo{
  height:40px;
  background-color: #ff9e03;
  margin-left: 20px;
  margin-right: 10px;
  width: 94%;
  
}
/* .fekkls{
  margin-left: 50px;
  margin-top: 60px;
 
  width: 60%;
}
.fekks{
  margin-left: 80px;
  margin-top: 60px;
  margin-bottom: 20px;
  height: 210px;
 
  width: 59%;
 
}
.feks{
  margin-left: 60px;
  margin-top: 65px;

  
  width: 54%;
 
}
.dow{
  position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color:white;
    transition: bottom 0.3s ease;
    cursor: pointer;
    border: none;
}

.dow:hover{
bottom: 0px;
}
.actorW{
  background-color: rgb(255, 255, 255);
   
  box-shadow: 0px 0px 10px #c7c7c7;
  width: 28%;
  height: 360px;
  margin-left: 50px;
  border-radius: 0px;
  position: relative;
 box-shadow: 0px 0px 3px rgb(114, 112, 112);
 border: none;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  
}
.actoW{
  background-color: rgb(255, 255, 255);
   
  box-shadow: 0px 0px 10px #c7c7c7;
  width: 28%;
  height: 360px;
  margin-left: 350px;
    margin-top: -360px;
  border-radius: 0px;
  position: relative;
 box-shadow: 0px 0px 3px rgb(114, 112, 112);
 border: none;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  
}
.actW{
  background-color: rgb(255, 255, 255);
   
  box-shadow: 0px 0px 10px #c7c7c7;
  width: 28%;
  height: 360px;
  margin-left: 650px;
  margin-top: -360px;
  border-radius: 0px;
  position: relative;
 box-shadow: 0px 0px 3px rgb(114, 112, 112);
 border: none;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  
}
.dow:hover::before{
  transform: rotate(20deg);
  box-shadow: 0 2px 20px rgba(0, 0,0, .2);
}
.dow:hover::after{
  transform: rotate(10deg);
  box-shadow: 0 2px 20px rgba(0, 0,0, .2);
} */
/* For screens smaller than 768px */
.container {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  
  padding: 0 20px;
}

.actorWk,
.actoWl,
.actWn {
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px #c7c7c7;
 width: 40%;
  height: 300px;
  border-radius: 10px;
  position: relative;
  /* box-shadow: 0px 0px 3px rgb(114, 112, 112); */
  border: none;
  background: #fff;
  overflow: hidden;
  margin-top: 50px;
  margin-left: 10px;
}

.fekkls,
.feks,
.fekks {
  width: 40%;
  margin-left: 60px;
  padding-left: 90px;
  margin-top: 20px;
}
.fekks{
  width: 40%;
  margin-left: 60px;
  padding-left: 90px;
  margin-top: 50px;
}

.dow{
  position: absolute;
    bottom: -50px;
    left:0px;
    width: 100%;
    padding: 20px;
    background-color:white;
    transition: bottom 0.3s ease;
    cursor: pointer;
    margin-top: 5%;
    margin-top: 50px;
    border: none;
}
.dows{
  position: absolute;
    bottom: -50px;
    left:0px;
    width: 100%;
    padding: 20px;
    background-color:white;
    transition: bottom 0.3s ease;
    cursor: pointer;
  
    margin-top: 300px;
    border: none;
}

.dow:hover {
  top: -80px;
}


.saha {
  margin-top: -15px;
  margin-left: -6px;
}

.dent {
  margin-top: -35px;
  font-size: 17px;
}
@media screen and (max-width: 768px) {
  .image {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-top: 0;
  }

  .right-arrow,
  .left-arrow {
    font-size: 1.5rem;
    top: 45%;
  }

  .slider {
    height: auto;
    margin-top: 20px;
  }

  .aim, .aims {
    margin-left: -20px;
    text-align: center;
   
  }

  .hip,
  .fpo {
    width: 90%;
    margin: 0 auto;
  }

  .i,
  .ince,
  .inc {
    margin-left: 0;
    
    font-size: 16px;
  }
.i{
  margin-left: 10px;
}
#az{
  margin-top: 150px;
}
.pnb{
  display: inline;
}
  .actorW,
  .actoW,
  .actW {
    width: 58%;
     display: flex; /* Use flexbox for better alignment */
   height: 360px;
    margin-top: 20px; /* Add some top margin */
    margin-bottom: 20px; /* Add some bottom margin */
  }
  .actw{
    margin-left: -70px;
  }
  .ssx,
  .ssx,
  .ssx {
    width: 58%;
    margin: 0 auto;
    display: flex; /* Use flexbox for better alignment */
   
   
  }
}

/* For larger screens */
@media screen and (min-width: 769px) {
  .dow {
    bottom: -50px;
    padding: 20px;
    width: 100%;
    left: 0;
  }
}
.pnb {
  display: flex;
  justify-content: space-between;
  margin-left: 150px;
  margin-right: 150px;
  margin-top: 30px;
}

.ssx {
  max-width: 16%;
  height: auto;
}

@media screen and (max-width: 768px) {
  .pnb {
      flex-direction: column;
      align-items: center;
      width: 90%;
      margin-left: -10px;
  }

  .ssx {
    max-width: 19%;
      margin: 10px auto;
  }
}

@media screen and (max-width: 1440px) {
  .image {
    width: 100%;
    height: auto;
    border-radius: 10px; /* Keep border-radius */
    margin-top: 0;
  }

  .right-arrow,
  .left-arrow {
    font-size: 1.5rem;
    top: 45%;
  }

  .slider {
    height: auto;
    margin-top: 20px;
  }

  .aim,
  .aims {
    margin-left: 0; /* Remove negative margin */
    text-align: center;
    margin-top: 10px; /* Adjust top margin */
  }

  .hip,
  .fpo {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px; /* Adjust top margin */
  }

  .i,
  .ince,
  .inc {
    margin-left: 0;
    font-size: 16px;
    margin-top: 10px; /* Adjust top margin */
  }

  .i {
    margin-left: 10px;
  }

  #az {
    margin-top: 150px;
  }

  .actorW
  {
    width: 50%;
    /* Adjusted width for better fit */
    margin: 0 auto; /* Centering horizontally */
    margin-top: 20px; /* Add some top margin */
    margin-bottom: 20px; /* Add some bottom margin */
  }

  .actw {
    margin-left: 0; /* Remove negative margin */
  }

  .ssx,
  .ssx,
  .ssx {
    width: 90%; /* Adjusted width for better fit */
    margin: 0 auto; /* Centering horizontally */
    display: flex; /* Use flexbox for better alignment */
  }

  .dow {
    position: relative; /* Change position to relative */
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: white;
    transition: bottom 0.3s ease;
    cursor: pointer;
    border: none;
  }

  .dow:hover {
    bottom: -50px; /* Change hover bottom position */
  }

  .actorW
  
 {
    /* Adjusted width for better fit */
    margin: 0 auto; /* Centering horizontally */
    margin-top: 20px; /* Add some top margin */
    margin-bottom: 20px; /* Add some bottom margin */
  }
  .actoW
  
 {

   margin-left: 80px;
    margin-bottom: 20px; /* Add some bottom margin */
  }

  

}


@media screen and (max-width: 425px) {
  .image {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-top: 0;
  }

  .right-arrow,
  .left-arrow {
    font-size: 1.5rem;
    top: 45%;
  }

  .slider {
    height: auto;
    margin-top: 20px;
  }

  .aim,
  .aims {
    margin-left: -20px;
    text-align: center;
  }

  .hip,
  .fpo {
    width: 90%;
    margin: 0 auto;
  }

  .i,
  .ince,
  .inc {
    margin-left: 0;
    font-size: 16px;
  }
  .i {
    margin-left: 10px;
  }
  #az {
    margin-top: 150px;
  }
  .pnb {
    display: inline;
  }
  .actorW,
  .actoW,
  .actW {
    width: 70%; /* Adjusted width for better fit */
    margin: 0 auto; /* Centering horizontally */
    display: flex; /* Use flexbox for better alignment */
    margin-top: 20px; /* Add some top margin */
    margin-bottom: 20px; /* Add some bottom margin */
  }
  .actow {
    margin-left: -70px;
  }
  .ssx,
  .ssx,
  .ssx {
    width: 90%; /* Adjusted width for better fit */
    margin: 0 auto; /* Centering horizontally */
    display: flex; /* Use flexbox for better alignment */
  }
}
@media (max-width: 1024px) {
  .container {
    flex-wrap: wrap;
    justify-content: center;
    
  }
  
  .actorW,
  .actoW,
  .actW {
    width: 45%;
  }
}

@media (max-width: 768px) {
  .actorW,
  .actoW,
  .actW {
    width: 90%;
  }
}

@media (max-width: 425px) {
  .actorW,
  .actoW,
  .actW {
    width: 200%;
    margin-top: 30px;
    
    
  }
  .container{
    display: inline;
    margin-left: 50px;
    
  }
  .container {
    flex-wrap: wrap;
    width: 50%;
    justify-content: center;
    
  }
  .fekkls,
.feks,
.fekks {
  width: 50%;
  margin-left: 20px;
  padding-left: 30px;
  margin-top: 20px;
}
}
