.up{
    margin-left: 40px;
    font-size: 32px;
    color: green;
}
.ups{
    margin-left: 40px;
    font-size: 30px;
    color: rgb(2, 2, 49);
    font-family:Montserrat;

}

table,th,td{
    border:groove;
    border-collapse: collapse;
    margin-left: 150px;
    margin-top: -50px;
   
}
.uct{
    margin-left: 180px;
}
tr,td{
    width: 120px;
    text-align: center;
}
th{
    color: rgb(51, 51, 51);
}
@media (max-width: 768px) {
    .up, .ups {
        margin-left: 5%; /* Adjust margin for smaller screens */
        font-size: 16px; /* Reset font size for smaller screens */
    }

    table, th, td {
        margin-left: 5%; /* Adjust margin for smaller screens */
        margin-top: -20px; /* Adjust margin top for smaller screens */
    }

    .uct {
        margin-left: 5%; /* Adjust margin left for smaller screens */
    }

    tr, td {
        width: auto; /* Reset width for smaller screens */
    }
}