.act{
    margin-top: 30px;
    margin-left: 20px;
    line-height: 30.6px;
    font-size: 18px;
    word-spacing: 2.7px;
    margin-right: 20px;
    color: rgb(107, 105, 105);
}
.pil{
    margin-left: 40px;
    font-size: 28px;
    color: green;
}