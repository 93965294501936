
.gifs{
    width:70%;
    height: 110px;
    margin-top: 50px;
    margin-left: 160px;
    border-radius: 10px;
    padding-top: 40px;
    background-color:rgb(237, 239, 240);
}
.gifsm{
    width:70%;
    height: 110px;
    margin-top: 20px;
    margin-left: 160px;
    padding-top: 40px;
    border-radius: 10px;
    background-color: rgb(237, 239, 240)
}
.gifsn{
    width:70%;
    height: 740px;
    margin-top: 50px;
    margin-left: 160px;
    padding-top: 40px;
    border-radius: 10px;
    background-color:rgb(237, 239, 240);
}
.d-flex {
    display: flex; 
  }
  
  .flex-shrink-0 {
    flex-shrink: 0; 
  }
  
  .align-items-center {
    align-items: center; 
  }
  
  .justify-content-center {
    justify-content: center; 
  }
  
  .rounded-circle {
    border-radius: 50%;
    margin-top: 30px; 
  }
  
  .bg-white {
    background-color: #ffffff; 
  }
  .d-flex {
margin-top: 250px;
  }
  .mza{
    width: 20px;
    height: 40px;
    color: rgb(63, 63, 255);
  }
  .cxz{
    margin-left: 290px;
    margin-top: -65px;
    font-size: 20px;
    padding-left: 90px;
    color: rgb(80, 79, 79);
  } 
  .zxc{
    margin-left: 180px;
    margin-top: -15px;
    padding-left: 90px;
    font-size: 17px;
    color: rgb(0, 0, 0);
  }
  .zxcm{
    margin-left: 160px;
    margin-top: -15px;
    padding-left: 90px;
    font-size: 20px;
    color: rgb(0, 0, 0);
  }
  .fsn{
    margin-left: 70px;
    font-size: 20px;
    color: rgb(80, 79, 79);
    border: groove;
    width: 130px;
    height: 30px;
    padding-top: 2px;
    border-radius: 30px;
    padding-left: 20px;
  }
  .wqd{
    margin-left: 80px;
    font-size: 36px;
    color: rgb(34, 34, 54);
  }
  .cxzmn{
    margin-left: 80px;
    margin-top: -5px;
    font-size: 20px;
    color: rgb(80, 79, 79);
  }
 
 .form-control{
  border: none;
 
  width: 37%;
  height: 55px;
 border: 1px solid;
 border-color:rgb(186, 227, 243);
  border-radius: 6px;
  font-size: 19px;
  margin-left: 50px;
 
  margin-top: 10px;
  padding-left: 20px;
  color: rgb(189, 187, 187);
 }
 .names{
  margin-top: -350px;

 }
 .fomes{
  border: none;
 
  width: 37%;
  height: 55px;
 margin-left: 60px;
 border: 1px solid;
 border-color:rgb(186, 227, 243);
  border-radius: 6px;
  font-size: 19px;
  padding-left: 20px;
 }
 .fomesm{
  border: none;
 
  width: 85%;
  height: 55px;
 margin-left: 50px;
 border: 1px solid;
 border-color:rgb(186, 227, 243);
  border-radius: 6px;
  font-size: 19px;
  padding-left: 20px;
  margin-top: 30px;
 }
 .fomesmn{
  margin-left: 50px;
 border: 1px solid;
 border-color:rgb(186, 227, 243);
  border-radius: 6px;
  font-size: 19px;
  padding-left: 20px;
  margin-top: 30px;
  padding-top: 20px;
  color: rgb(189, 187, 187);
 }
 .fomesmnp{
  border: none;
 
  width: 87%;
  height: 57px;
 margin-left: 50px;
 border: 1px solid;
 border-color:rgb(186, 227, 243);
  border-radius: 6px;
  font-size: 19px;
  padding-left: 20px;
  margin-top: 30px;
  background-color: rgb(86, 86, 247);
  color: white;
 } 

@media screen and (max-width: 768px) {
  .gifs,
  .gifsm,
  .gifsn {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
  }

  .names {
      margin-top: -250px;
  }

  .form-control,
  .fomes,
  .fomesm,
  .fomesmn,
  .fomesmnp {
      width: 90%;
      margin-left: auto;
      margin-right: auto;
  }
}

@media screen and (min-width: 769px) {
  .d-flex {
      margin-top: 150px; /* Adjust as needed */
  }

  .cxz,
  .zxc,
  .zxcm,
  .fsn,
  .wqd,
  .cxzmn {
      margin-left: 30px;
      padding-left: 20px;
  }
}
@media screen and (min-width: 425px) {
  .d-flex {
      margin-top: 150px; /* Adjust as needed */
  }

  .cxz,
  .zxc,
  .zxcm
 
  {
      margin-left:30px;
      padding-left: 120px;
      font-size: 12px;
  }
  .wqd,
  .cxzmn{font-size: 12px;

  }
}

