/* .pitr{
    width:32%;
    height:400px;
    border:1px solid rgb(100, 179, 175);
}
.pitr:hover{
box-shadow: 0px 0px 10px #7c7c7c;
transform: scale(1.1);

}
.kop{
    margin-top: 30px;
    margin-left: 30px;
}
#dj:hover{
    background-color: skyblue;
} */




/* .head{display:flex;}
        #ipllogo{padding-right:10px;}
        #tata{height:100px;width:200px;padding-left:37%;}
        #cup{height:100px;width:80px;padding-left:30%;opacity:60%;}hr{opacity:50%;}
        .selnam{border:3px solid black;height:40px;margin:30px 10px 0px 30%;font-weight:bold;}
        option{font-size:25px;font-weight:bold;border:2px solid whitesmoke;}
        .div_right{display:flex;margin-top:10px;padding-top:30px;padding-bottom:40px;}
        #getimage{height:300px;width:350px;padding-right:10px;padding-left:10px;}
        #getjersey{height:300px;width:600px;}
        .divplay{margin-right:30px;margin-left:30px;margin-top:30px;margin-bottom:30px;padding-bottom:40px;}
        #getplayers{font-size:30px;color:beige;background-color:black;}
        .diven{border:10px 10px;margin:10px 10px;}
        #getvenue{height:300px;width:800px;}
        .divteam{margin-top:20px;}  */ 
        .pitr{
            width:30%;
            height:300px;
        }
        .pitr:hover{
        box-shadow: 0px 0px 10px #7c7c7c;
        transform: scale(1.1);
        
        }
        .kop{
            margin-top: 90px;
            margin-left: 30px;
        }
        #fgh{
            display: flex;
        }
        #dj:hover{
            background-color: skyblue;
        }
        @media (max-width: 768px) {
            .pitr {
                width: 90%; /* Adjust width for screens up to 768px */
                height: auto; /* Reset height for smaller screens */
            }
        
            .kop {
                margin-top: 10vw; /* Adjust margin top for screens up to 768px */
                margin-left: 5%;
                 /* Adjust margin left for screens up to 768px */
            }
        }
        
        @media (max-width: 600px) {
            .pitr {
                width: 90%; /* Further adjust width for screens up to 600px */
            }
        
            .kop {
                width: 45%px;
                margin-top: 15vw; 
             /* Further adjust margin top for screens up to 600px */
            }
        }
        @media (max-width: 768px) {
            #fgh {
                flex-direction: column; /* Change flex direction for screens up to 768px */
            }
            
        }
        .gallery-grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
        
        .gallery-item {
            margin: 10px;
            max-width: calc(33.33% - 20px);
        }
        
        @media (max-width: 768px) {
            .gallery-item {
                max-width: calc(20% - 20px);
                margin-left:-500px;
            }
        }
        
        @media (max-width: 480px) {
            .gallery-item {
                max-width: calc(100% - 20px);
            }
        }
        