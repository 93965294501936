#tcx {
    margin-top: 30px; /* Adjusted margin top */
    margin-right: 20px; /* Adjusted margin right */
    margin-left: 20px; /* Adjusted margin left */
    line-height: 25px; /* Adjusted line height */
    font-size: 16px; /* Adjusted font size */
    word-spacing: 3px; /* Adjusted word spacing */
    width: 49%; /* Full width */
    color: rgb(107, 105, 105);
    padding-top: 60px;
}

#as {
    border-radius: 5px;
    margin-bottom: 20px; /* Adjusted margin bottom */
    margin-top: -290px; /* Adjusted margin top */
    width: 49%; /* Full width */
    margin-left: 480px; /* Centering horizontally */
    margin-right: auto; /* Centering horizontally */
}

#act {
    margin-top: -240px; /* Adjusted margin top */
    margin-right: 20px; /* Adjusted margin right */
    margin-left: 600px; /* Adjusted margin left */
    line-height: 25px; /* Adjusted line height */
    font-size: 16px; /* Adjusted font size */
    word-spacing: 3px; /* Adjusted word spacing */
    color: rgb(107, 105, 105); 
}
#asm{
        
    margin-top: 30px; /* Adjusted margin top */
  
    margin-left: 20px; /* Adjusted margin left */
    line-height: 25px; /* Adjusted line height */
    font-size: 16px; /* Adjusted font size */
    word-spacing: 3px; /* Adjusted word spacing */
   
    color: rgb(107, 105, 105);
    padding-top: 60px;
}

#sed {
    margin-left: 20px; /* Adjusted margin left */
    width: 50%; /* Full width */
    height: auto; /* Allowing height to adjust */
    margin-top: 10px; /* Adjusted margin top */
    border-radius: 5px;
}
#klq{
    margin-left: 20px; /* Adjusted margin left */
    width: 50%; /* Full width */
    height: auto; /* Allowing height to adjust */
    margin-top: 10px; /* Adjusted margin top */
    border-radius: 5px;
}
.bode{
    width: 30%;
    margin-left: 90px;
}
@media screen and (max-width: 325px) {
    #tcx {
        margin-top: 20px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        width: 100%; /* Full width */
        padding-top: 30px; /* Adjusted padding top */
    }
    #sed{
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }
    #asm{
        margin-top: -40PX;
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
        padding-top: -60PX;
    }
    
    

    #as {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }

    #act {
        margin-top: 10px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        color: rgb(107, 105, 105); 
    }
    .klq{
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
      
    }
}
@media screen and (max-width: 425px) {
    #tcx {
        margin-top: 20px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        width: 100%; /* Full width */
        padding-top: 30px; /* Adjusted padding top */
    }

    #sed {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }

    #asm {
        margin-top: -40px; /* Adjusted negative margin top */
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
        padding-top: 0; /* Removed negative padding top */
    }

    #as {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }

    #act {
        margin-top: 10px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        color: rgb(107, 105, 105); 
    }

    .klq {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }
}
@media screen and (max-width: 1440px) {
    #tcx {
        margin-top: 30px; /* Adjusted margin top */
        margin-right: 20px; /* Adjusted margin right */
        margin-left: 20px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
        width: 48%; /* Full width */
        color: rgb(107, 105, 105);
        padding-top: 120px;
    }
    
    #as {
        border-radius: 5px;
        margin-bottom: 20px; /* Adjusted margin bottom */
        margin-top: -280px; /* Adjusted margin top */
        width: 48%; /* Full width */
        margin-left: 730px; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }
    
    #act {
        margin-top: -280px; /* Adjusted margin top */
        margin-right: 20px; /* Adjusted margin right */
        margin-left: 760px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
        color: rgb(107, 105, 105); 
    }
    #asm{
            
        margin-top: 130px; /* Adjusted margin top */
      
        margin-left: 20px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
       
        color: rgb(107, 105, 105);
        padding-top: 60px;
    }
    
    #sed {
        margin-left: 20px; /* Adjusted margin left */
        width: 50%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin-top: 10px; /* Adjusted margin top */
        border-radius: 5px;
    }
    #klq{
        margin-left: 20px; /* Adjusted margin left */
        width: 50%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin-top: 10px; /* Adjusted margin top */
        border-radius: 5px;
    }
    .bode{
        width: 30%;
        margin-left: 90px;
        margin-top: 50px;
    }
}

@media screen and (max-width: 1024px) {
    #tcx {
        margin-top: 30px; /* Adjusted margin top */
        margin-right: 20px; /* Adjusted margin right */
        margin-left: 20px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
        width: 49%; /* Full width */
        color: rgb(107, 105, 105);
        padding-top: 60px;
    }
    
    #as {
        border-radius: 5px;
        margin-bottom: 20px; /* Adjusted margin bottom */
        margin-top: -290px; /* Adjusted margin top */
        width: 49%; /* Full width */
        margin-left: 520px; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }
    
    #act {
        margin-top: -240px; /* Adjusted margin top */
        margin-right: 20px; /* Adjusted margin right */
        margin-left: 580px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
        color: rgb(107, 105, 105); 
    }
    #asm{
            
        margin-top: 30px; /* Adjusted margin top */
      
        margin-left: 20px; /* Adjusted margin left */
        line-height: 25px; /* Adjusted line height */
        font-size: 16px; /* Adjusted font size */
        word-spacing: 3px; /* Adjusted word spacing */
       
        color: rgb(107, 105, 105);
        padding-top: 60px;
    }
    
    #sed {
        margin-left: 20px; /* Adjusted margin left */
        width: 50%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin-top: 10px; /* Adjusted margin top */
        border-radius: 5px;
    }
    #klq{
        margin-left: 20px; /* Adjusted margin left */
        width: 50%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin-top: 10px; /* Adjusted margin top */
        border-radius: 5px;
    }
    .bode{
        width: 30%;
        margin-left: 90px;
    }
}
@media screen and (max-width: 768px) {
    #tcx {
        margin-top: 20px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        width: 100%; /* Full width */
        padding-top: 30px; /* Adjusted padding top */
    }

    #sed {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }

    #asm {
        margin-top: -40px; /* Adjusted negative margin top */
        border-radius: 5px;
        font-size: 14px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
        padding-top: 0; /* Removed negative padding top */
    }

    #as {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }

    #act {
        margin-top: 10px; /* Adjusted margin top */
        margin-right: 10px; /* Adjusted margin right */
        margin-left: 10px; /* Adjusted margin left */
        line-height: 22px; /* Adjusted line height */
        font-size: 14px; /* Adjusted font size */
        word-spacing: 2px; /* Adjusted word spacing */
        color: rgb(107, 105, 105); 
    }

    .klq {
        border-radius: 5px;
        margin-bottom: 10px; /* Adjusted margin bottom */
        margin-top: 10px; /* Adjusted margin top */
        width: 100%; /* Full width */
        margin-left: auto; /* Centering horizontally */
        margin-right: auto; /* Centering horizontally */
    }
}



