 .bodr{
    font-size: 43px;
    color: rgb(177, 173, 173);
    margin-left: 390px;
}
.fekkl{
    margin-left: 190px;
    margin-top: 30px;
    border-radius: 50%;
   padding-left: 50px;
    width: 54%;
}
.jlo{
    display: flex;
    margin-right: 50px;
}
.fekk{
    margin-left: 190px;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 70px;
    border-radius: 50%;
    width: 56%;
   
}
.fek{
    margin-left: 80px;
    margin-top: 30px;
 padding-left: 50px;
    border-radius: 50%;
    width: 51%;
   
}
.bnm:hover{
    transform: scale(1.0);
}
.ctor{
    background-color: rgb(255, 255, 255);
   
    box-shadow: 0px 0px 10px #c7c7c7;
    width: 30%;
    height: 360px;
    margin-left: 50px;
    border-radius: 0px;
}
.ctor:hover{
    box-shadow: 0px 0px 30px #c7c7c7;
}
.cto{
    background-color: rgb(255, 255, 255);
   
    box-shadow: 0px 0px 10px #c7c7c7;
    width: 30%;
    height: 360px;
    margin-left: 360px;
    margin-top: 2px;
    border-radius: 0px;
}
.cto:hover{
    box-shadow: 0px 0px 30px #c7c7c7;
}
.ct{
    background-color: rgb(255, 255, 255);
   
    box-shadow: 0px 0px 10px #c7c7c7;
    width: 30%;
    height: 360px;
    margin-left: 630px;
    margin-top: 2px;
    border-radius: 0px;
   
}
.ct:hover{
    box-shadow: 0px 0px 30px #c7c7c7;
}
.saha{
    margin-left: 70px;
    font-size: 22px;
    margin-top: 20px;
}
.dent{
    margin-left: 40px;
    font-size: 17px;
    margin-top: -22px;
}
.mom{
    background-color: rgb(229, 236, 229);
} 
   
     

@media (max-width: 1200px) {
    .bodr {
        margin-left: 5%;
    }
    .fekkl, .fekk, .fek {
        margin-left: 3%;
        width: 50%;
    }
    .ctor, .cto, .ct {
        width: 60%;
        margin-left: 5%;
        margin-top: 20px;
    }
   
}

@media (max-width: 768px) {
    .bodr {
        margin-left: 5%;
    }
    .fekkl, .fekk, .fek {
        margin-left: 3%;
        width: 40%;
    }
    .ctor, .cto, .ct {
        width: 80%;
        margin-left: 5%;
    }
    
}

/* Additional adjustments for responsiveness */
@media (max-width: 600px) {
    .saha {
        margin-left: 5%;
    }
    .dent {
        margin-left: 5%;
    }
    .jlo{
        display: inline;
    }
}
@media (max-width: 1440px) {
    .saha {
        margin-left: 5%;
    }
    .dent {
        margin-left: 5%;
    }
  
}
@media (min-width: 1024px) {
    /* CSS rules specific to screens with a width of 1440 pixels or larger */
    .aim {
      margin-left: 20%;
    }
    .saha {
        margin-left: 5%;
    }
    .dent {
        margin-left: 5%;
    }
    .aims {
      margin-left: 10%;
    }
  
    .hip,
    .fpo {
      margin-left: 1.39%;
      margin-right: 0.69%;
    }
  
    .i {
      margin-left: 2.78%;
    }
  
    .fekkls {
      margin-left: 3.47%;
    }
  
    .fekks {
      margin-left: 5.56%;
    }
  
    .feks {
      margin-left: 4.17%;
    }
  
    .actorW {
      margin-left: 1.39%;
    }
  
    .actoW {
      margin-left: 24.31%;
    }
  
    .actW {
      margin-left: 47.22%;
    }
  }
  @media screen and (max-width: 1440px) {
    .aim {
        margin-left: 20%;
      }
      .ctor, .cto, .ct {
        width: 60%;
        margin-left: 5%;
        margin-top: 20px;
    }
   
    
      .aims {
        margin-left: 10%;
      }
    
      .hip,
      .fpo {
        margin-left: 1.39%;
        margin-right: 0.69%;
      }
    
      .i {
        margin-left: 2.78%;
      }
    
      .fekkls {
        margin-left: 3.47%;
      }
    
      .fekks {
        margin-left: 5.56%;
      }
    
      .feks {
        margin-left: 4.17%;
      }
    
      .actorW {
        margin-left: 1.39%;
      }
    
      .actoW {
        margin-left: 24.31%;
      }
      .fekkl, .fekk, .fek {
        margin-left: 3%;
        width: 50%;
    }
    .fek{
        margin-left: 40px;
    }
    
      .actW {
        margin-left: 47.22%;
      }
    
  }
  
  
  
