/* footer{background-color: rgb(219, 247, 219);
margin-top: 100px;
}
.heap{font-weight: bolder;font-size:20px;}
.separate-two-div{width:100%;}
    .usefullinks{display:grid;grid-template-columns: repeat(4,1fr);grid-gap: 10px;margin-top:7px;}
    button{background-color: transparent;border: none;}
    .uselinks{text-decoration: none;font-weight: bold;}
.foot-div-bottom{background-color:#ff9e03;text-align: center;padding:2px;}
.foot-address{width:30%;float: left;}
.social-media-links{width:20%;float:left;}
    .social-ul{list-style: none;}
    .social-ul-li{margin-top: 15px;}
.navigate-map{width:50%;float:left;}
.map-place{width:600;height:250;border:0;padding:10px 30px 10px 30px;}
.usefullink{
    margin-left: 280px;
}
.foot-address{
    margin-left: 40px;
    margin-top: -10px;
}
.and{
    margin-left: 280px;
    margin-top: -64px;
    font-size: 28px;
    font-weight: bolder;
}
.heaps{
    margin-left: 20px;
    margin-top: 26px;
    font-size: 28px;
    font-weight: bolder;
}
.navigate-map{
    margin-left: 450px;
    margin-top: -290px;
}
.map-place{
    margin-left: 120px;
    height: 280px;
    width: 380px;
    margin-top: -30px;
}
.topl{
    margin-left: 100px;
    line-height: 32px;
    list-style-type:none;
    list-style-type: none;
    padding-top: -30px;
    color: black;
}

Link{
    text-decoration: none;
    list-style-type: none;
}
.social-media-links{
    margin-top: -60px;
    margin-left: 70px;
}
.heapw{
    margin-left: 20px;
    margin-top: -26px;
    font-size: 28px;
    font-weight: bolder;
}
.buttlo{
    text-decoration: none;
    color: black;
} */
 /* Base styles */
footer {
    background-color: rgb(219, 247, 219);
    margin-top: 100px;
}

.heap {
    font-weight: bolder;
    font-size: 20px;
}

.separate-two-div {
    width: 100%;
}

.usefullinks {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    margin-top: 7px;
    margin-left: 700px;
    padding-left: -300px;
}
.usefullink{
    margin-left: -280px;
}

button {
    background-color: transparent;
    border: none;
}
.navigate-map {
    width: 100%;
    float: none;
    margin-left: 0;
    margin-top: 20px;
    text-align: center;
}

.uselinks {
    text-decoration: none;
    font-weight: bold;
}

.foot-div-bottom {
    background-color: #ff9e03;
    text-align: center;
    padding: 2px;
}
.va{
    
    padding-top: 20px;
    padding-left: 300px;
}
.social-media-links {
     
    margin-top: 30px;
    margin-right: 30px;
    padding-right: 300px;
       
       
   }
  

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .foot-address {
        width: 90%; /* Adjusted width for better fit */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 10px; /* Positive margin for spacing */
        text-align: center; /* Aligning content to center */
        flex-direction: row;
    }
    .usefullink {
        margin-left: -520px;
        list-style-type: none;
    }
   
}


@media screen and (max-width: 1440px) {
    .foot-address {
        width: 33%;
        float: left;
        margin-left: 40px;
        margin-top: -10px;
        flex-direction: row;
    }
    .heaps{
        font-family: bolder;
        font-size: 22px;
    }
    .va{
        padding-left: -300px;
    }

    .navigate-map {
        width: 50%;
        float: left;
        margin-left: 550px;
        margin-top: -290px;
    }

    .map-place {
        width: 380px;
        height: 280px;
        margin-left: 120px;
        margin-top: -30px;
    }

    .usefullink {
        margin-left: 30px;
        list-style-type: none;
    }

    .and {
        margin-left: 280px;
        margin-top: -64px;
        font-size: 28px;
        font-weight: bolder;
    }

    .heaps {
        margin-left: 20px;
        margin-top: 26px;
        font-size: 28px;
        font-weight: bolder;
    }

    .topl {
        margin-left: 100px;
        line-height: 32px;
        list-style-type: none;
        padding-top: -30px;
        color: black;
    }

    .social-media-links {
        margin-top: -60px;
        margin-left: 70px;
    }

    .heapw {
        margin-left: 20px;
        margin-top: -26px;
        font-size: 28px;
        font-weight: bolder;
    }

    .buttlo {
        text-decoration: none;
        color: black;
        list-style-type: none;
    }
}

   
@media screen and (max-width: 1024px) {
    .foot-address {
        width: 30%;
        float: left;
        margin-left: 40px;
        margin-top: -10px;
        flex-direction: row;
    }
    .heaps{
        font-family: bolder;
        font-size: 22px;
    }
    .va{
        padding-left: -300px;
    }

    .navigate-map {
        width: 50%;
        float: left;
        margin-left: 500px;
        margin-top: -290px;
    }

    .map-place {
        width: 380px;
        height: 280px;
        margin-left: 120px;
        margin-top: -30px;
    }

    .usefullink {
        margin-left: 300px;
        list-style-type: none;
    }

    .and {
        margin-left: 280px;
        margin-top: -64px;
        font-size: 28px;
        font-weight: bolder;
    }

    .heaps {
        margin-left: 20px;
        margin-top: 26px;
        font-size: 28px;
        font-weight: bolder;
    }

    .topl {
        margin-left: 100px;
        line-height: 32px;
        list-style-type: none;
        padding-top: -30px;
        color: black;
    }

    .social-media-links {
        margin-top: -60px;
        margin-left: 70px;
    }

    .heapw {
        margin-left: 20px;
        margin-top: -26px;
        font-size: 28px;
        font-weight: bolder;
    }

    .buttlo {
        text-decoration: none;
        color: black;
        list-style-type: none;
    }
}
@media screen and (max-width: 425px) {
    .foot-address {
        width: 90%; /* Adjusted width for better fit */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 10px; /* Positive margin for spacing */
        text-align: center; /* Aligning content to center */
        flex-direction: row; /* Ensuring row direction for flexbox */
    }

    .heaps {
        font-family: bolder; /* Setting font family to bolder */
        font-size: 22px; /* Adjusted font size */
    }

    .va {
        padding-left: 0; /* Reset padding */
    }
    .social-media-links {
        margin-top: 245px; /* Adjusted margin for spacing */
        margin-left: 50px; /* Reset margin */
    }
    .qv{
        margin-left: -210px;
    }

    .navigate-map {
        width: 90%; /* Adjusted width for better fit */
        margin-left: 90px; /* Adjusted left margin */
        margin: 50px auto 0; /* Centering horizontally and adding margin top for spacing */
    }

    .map-place {
        width: 100%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 20px; /* Adding margin top for spacing */
    }

    .usefullink {
        list-style-type: none; /* Removed list style */
        margin-top: 290px; /* Adjusted top margin */
        margin-left: 0px; /* Adjusted left margin */
    }

    .and {
        font-size: 28px; /* Adjusted font size */
        font-weight: bolder; /* Set font weight to bolder */
        margin-left: -50px; /* Adjusted left margin */
        padding-top: 0px;
    }

    /* Other adjustments for remaining elements... */
}
@media screen and (max-width: 320px) {
    .foot-address {
        width: 90%; /* Adjusted width for better fit */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 10px; /* Positive margin for spacing */
        text-align: center; /* Aligning content to center */
        flex-direction: column; /* Adjusting to column direction for smaller screens */
    }

    .heaps {
        font-size: 18px; /* Adjusted font size for smaller screens */
    }

    .va {
        padding-left: 0; /* Reset padding */
    }

    .social-media-links {
        margin-top: 240px; /* Adjusted margin for spacing */
        margin-left: 20px; /* Reset margin */
        /* Centering links for smaller screens */
    }
    .qv{
        margin-left: -230px;
    }

    .navigate-map {
        width: 80%; /* Adjusted width for better fit */
        margin: -30px auto 0; /* Centering horizontally and adding margin top for spacing */
    }

    .map-place {
        width: 100%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin: 20px auto; /* Centering horizontally and adding margin top for spacing */
    }

    .usefullink {
        margin-top: 20px; /* Adjusted top margin */
        margin-left: 90px; /* Reset margin */
        text-align: center; /* Centering links for smaller screens */
    }

    .and {
        font-size: 24px; /* Adjusted font size for smaller screens */
        margin-left: 20px; 
        margin-top: -65px;/* Reset margin */
        text-align: center; /* Centering text for smaller screens */
    }

    /* Additional adjustments for remaining elements as needed */
}
@media screen and (max-width: 768px) {
    .foot-address {
        width: 90%; /* Adjusted width for better fit */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 10px; /* Positive margin for spacing */
        text-align: center; /* Aligning content to center */
        flex-direction: row; /* Ensuring row direction for flexbox */
    }

    .heaps {
        font-family: bolder; /* Setting font family to bolder */
        font-size: 22px; /* Adjusted font size */
    }

    .va {
        padding-left: 0; /* Reset padding */
    }
    .social-media-links {
        margin-top: 245px; /* Adjusted margin for spacing */
        margin-left: 50px; /* Reset margin */
    }
    .qv{
        margin-left: -210px;
    }

    .navigate-map {
        width: 90%; /* Adjusted width for better fit */
        margin-left: 90px; /* Adjusted left margin */
        margin: 50px auto 0; /* Centering horizontally and adding margin top for spacing */
    }

    .map-place {
        width: 100%; /* Full width */
        height: auto; /* Allowing height to adjust */
        margin: 0 auto; /* Centering horizontally */
        margin-top: 20px; /* Adding margin top for spacing */
    }

    .usefullink {
        list-style-type: none; /* Removed list style */
        margin-top: 290px; /* Adjusted top margin */
        margin-left: 40px; /* Adjusted left margin */
    }

    .and {
        font-size: 28px; /* Adjusted font size */
        font-weight: bolder; /* Set font weight to bolder */
        margin-left: -50px; /* Adjusted left margin */
        padding-top: 0px;
    }

    /* Other adjustments for remaining elements... */
}

